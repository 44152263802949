<template>
  <div class="row">
    <div class="col-12 g">
        <div class="card card-body">
            <h4>
                طلبات اليوم
            </h4>
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" style="width: 50px; height; 50px;" alt="">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                           # 
                        </th>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهاتف
                        </th>
                        <th>
                            الفندق
                        </th>
                        <th>
                            تاريخ الدخول
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            المبلغ
                        </th>
                        <th>
                            العمولة
                        </th>
                        <th>
                            الغاء الحجز
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders_1" :key="order._id">
                            <td>
                                {{ order.id }}
                            </td>
                            <td>
                                {{ order.name }}
                            </td>
                            <td>
                                {{ order.phone }}
                            </td>
                            <td>
                                {{ order.hotel.title }}
                            </td>
                            <td>
                                {{ order.days[0] }} ({{ order.days.length-1 }} يوم)
                            </td>
                            <td>
                                <span class="btn btn-sm btn-warning" style="border-radius: 0px;" v-if="order.status == 0">في الانتظار</span>
                                <span class="btn btn-sm btn-success" style="border-radius: 0px;" v-if="order.status == 1">تم التأكيد</span>
                                <span class="btn btn-sm btn-danger" style="border-radius: 0px;" v-if="order.status == 2">في انتظار الالغاء</span>
                                <span class="btn btn-sm btn-secondary" style="border-radius: 0px;" v-if="order.status == 3">ملغي</span>
                            </td>
                            <td>
                                {{ order.total_reseller }}
                            </td>
                            <td>
                                {{ order.extra }}
                            </td>
                            <td>
                                <button class="btn text-danger btn-sm" v-if="order.status == 0 || order.status == 1" @click="current = order" v-b-modal.modal-1>
                                    <i class="fa fa-trash"></i>
                                    الغاء الحجز
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-12 g">
        <div class="card card-body">
            <h4>
                طلبات الغد
            </h4>
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" style="width: 50px; height; 50px;" alt="">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                           # 
                        </th>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهاتف
                        </th>
                        <th>
                            الفندق
                        </th>
                        <th>
                            تاريخ الدخول
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            المبلغ
                        </th>
                        <th>
                            العمولة
                        </th>
                        <th>
                            الغاء الحجز
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders_2" :key="order._id">
                            <td>
                                {{ order.id }}
                            </td>
                            <td>
                                {{ order.name }}
                            </td>
                            <td>
                                {{ order.phone }}
                            </td>
                            <td>
                                {{ order.hotel.title }}
                            </td>
                            <td>
                                {{ order.days[0] }} ({{ order.days.length-1 }} يوم)
                            </td>
                            <td>
                                <span class="btn btn-sm btn-warning" style="border-radius: 0px;" v-if="order.status == 0">في الانتظار</span>
                                <span class="btn btn-sm btn-success" style="border-radius: 0px;" v-if="order.status == 1">تم التأكيد</span>
                                <span class="btn btn-sm btn-danger" style="border-radius: 0px;" v-if="order.status == 2">في انتظار الالغاء</span>
                                <span class="btn btn-sm btn-secondary" style="border-radius: 0px;" v-if="order.status == 3">ملغي</span>
                            </td>
                            <td>
                                {{ order.total_reseller }}
                            </td>
                            <td>
                                {{ order.extra }}
                            </td>
                            <td>
                                <button class="btn text-danger btn-sm" v-if="order.status == 0 || order.status == 1" @click="current = order" v-b-modal.modal-1>
                                    <i class="fa fa-trash"></i>
                                    الغاء الحجز
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
     <b-modal id="modal-1" size="sm" title="الغاء الطلب" hide-footer>
        <h4>
            يرجى العلم ان الغاء الحجز بعد تأكيده من الفندق قد يترتب عليه خصم رسوم من رصيدك.
        </h4>
        <div class="form-group">
          <label for="">سبب الالغاء</label>
          <textarea class="form-control" rows="3" placeholder="اكتب هنا..." v-model="reason"></textarea>
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-danger" @click="cancel()">
                الغاء الحجز الآن
            </button>
        </div>
     </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    components: {
        BModal,BFormCheckbox,
    },
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            orders_1: [],
            orders_2: [],
            loading: false,
            current: {},
            reason: "",
        }
    },
    created(){
        var g = this;
            g.loading = true;
            $.post(api + '/reseller/orders/index', {
                jwt: this.jwt,
                page: 0,
                date: new Date().toISOString().split("T")[0],
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.orders_1 = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
            var s = new Date();
            s.setDate(s.getDate() + 1)
            $.post(api + '/reseller/orders/index', {
                jwt: this.jwt,
                page: 0,
                date: s.toISOString().split("T")[0],
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.orders_2 = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
    },
    methods: {
        cancel(){
            var g = this;
            $.post(api + '/reseller/orders/cancel', {
                jwt: this.jwt,
                id: this.current._id,
                reason: this.reason
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    $("#modal-1___BV_modal_header_ > button").click()
                    location.reload()
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        }
    }
}
</script>

<style>

</style>